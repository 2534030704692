import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import {Routes, Route, Outlet, Link, Navigate, useNavigate} from "react-router-dom";
import { observer } from "mobx-react"
import {NOTE_TYPE, URL_SYNC_NOTES} from "../../utils/const";
import {notesStore} from "../../stores/NotesStore";
import {newRandomItemId, nowSec, requestPost, syncNotes} from "../../utils/common"
import {userStore} from "../../stores/UserStore";

export const Alert = observer(({
    popupStore
  }) => {


  return <div className={`alert ${popupStore.fadeClass}`}>
    <div className={"window"}>
      <div className={`icon ${popupStore.type}`}></div>
      <div className={"text"} dangerouslySetInnerHTML={{__html: popupStore.text}}></div>
      <div className={"buttons"}>
        {popupStore.buttons.map(button =>
          <button onClick={button.onClick}>{button.text}</button>)}
      </div>
    </div>
  </div>
});
