import React, {useEffect, useRef, useState, useTransition} from "react";
import {Routes, Route, Outlet, Link, useParams, Navigate, useNavigate} from "react-router-dom";
import "./style.scss";
import { observer } from "mobx-react"
import { getAuth, signOut } from "firebase/auth";
import {userStore} from "../../stores/UserStore";
import {DEVICE_PART_NUMBERS, getDeviceInfo} from "../../utils/partNumbers";
import {getFunctions, httpsCallable, connectFunctionsEmulator} from "firebase/functions";
import {ffunctions} from "../../utils/common";
import {popupStore} from "../../stores/PopupStore";
import {ALERT_TYPE, RESPONSE_ERRORS} from "../../utils/const";
import {useTranslation} from "react-i18next";
import {loaderStore} from "../../stores/LoaderStore";
import i18n from "../../i18n"

export const QRCodePage = observer(({userStore, popupStore}) => {

  const { t } = useTranslation();

  useEffect(() => {
    if(userStore.isDevicePaired(deviceId)) {
      navigate("/notes");
    } else {
    }
  }, [userStore.pairedDevices])

  const navigate = useNavigate();

  let {deviceId, partNumber} = useParams();


  let deviceInfo = getDeviceInfo(partNumber);

  async function tryPair(force = false) {
    loaderStore.show();
    let result = null;
    try {
      result = await httpsCallable(ffunctions(), "apiPair")({
        deviceId: deviceId,
        force: force
      });
    } catch (e) {
      popupStore.show(ALERT_TYPE.ERROR, i18n.t("Sync error"));
    }
    loaderStore.hide();

    if(!!result.data) {
      if(!!result.data.error) {
        if(result.data.error === RESPONSE_ERRORS.DEVICE_PAIRED_WITH_OTHER_USER) {
          popupStore.show(
            ALERT_TYPE.QUESTION,
            t("QRCode page. Repair. Text"),
            [{
              text: t("QRCode page. Repair. Button continue"),
              onClick: () => {
                popupStore.hide();
                setTimeout(() => tryPair(true), 1000);
              }
            },
              {
                text: t("QRCode page. Repair. Button cancel"),
                onClick: () => {
                  popupStore.hide();
                }
              }]
          );
        } else if(result.data.error === RESPONSE_ERRORS.WRONG_DEVICE_ID) {
          popupStore.showError(t("QRCode page. Error wrong device id"));
        } else {
          popupStore.showError(t("QRCode page. Error alert. Text", {error: result.data.error}));
        }
      } else {
        userStore.setPairedDevices(userStore.pairedDevices.concat([deviceId]));
        popupStore.showSuccess(t("QRCode page. Pair prompt. Text"), () => navigate("/notes"))
      }
    }
  }

  async function onAccept() {
    await tryPair();
  }

  function onReject() {
    navigate("/");
  }

  //if(!userStore.firebaseUser) return null;

  return <div className={"page qrcode-page"}>
    {deviceInfo && <div className={"image-wrapper"} style={{
      background: `url(${deviceInfo["imageUrl"]}) #ffffff no-repeat`,
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain"
    }}>
    </div>}

    {deviceInfo && <div className={"title"}>{deviceInfo["displayName"]}</div>}
    <div className={"text"}>{t("QRCode page. Text")}</div>
    <div className={"buttons"}>
      <button onClick={onAccept}>{t("QRCode page. Button OK")}</button>
      <button onClick={onReject}>{t("QRCode page. Button Cancel")}</button>
    </div>


  </div>
})
