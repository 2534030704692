import {makeObservable, observable} from "mobx"
import {ALERT_TYPE} from "../utils/const";
import {GoogleAuthProvider} from "firebase/auth";

class PopupStore {


  type = ALERT_TYPE.INFO;
  visible = false;
  text = "";
  buttons = [];
  fadeClass = "";

  constructor() {
    makeObservable(this, {
      type: observable,
      visible: observable,
      text: observable,
      buttons: observable,
      fadeClass: observable
    })
  }

  showError(text, callback = null) {
    this.show(ALERT_TYPE.ERROR, text, [{
      text: "Ok",
      onClick: () => {
        this.hide()
        if(callback)  callback();
      }
    }])
  }

  showSuccess(text, callback = null) {
    this.show(ALERT_TYPE.SUCCESS, text, [{
      text: "Ok",
      onClick: () => {
        this.hide()
        if(callback)  callback();
      }
    }])
  }

  show(type, text, buttons = [{
    text: "Ok",
    onClick: () => this.hide()
  }]) {
    this.type = type;
    this.visible = true;
    this.text = text;
    this.buttons = buttons;
    this.fadeClass = "fadeIn";
  }

  hide() {
    this.fadeClass = "fadeOut";
    setTimeout(() => {
      this.visible = false;
    }, 500)
  }

}

export const popupStore = new PopupStore();
