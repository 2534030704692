import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import {Routes, Route, Outlet, Link, useParams} from "react-router-dom";
import {notesStore} from "../../../stores/NotesStore";
import {nowSec} from "../../../utils/common";
import {useTranslation} from "react-i18next";

export function PlaintextNote(props) {

  let id = props.noteId;

  const textRef = useRef();

  const { t } = useTranslation();

  const [text, setText] = useState("");

  useEffect(() => {
    const note = notesStore.getNoteById(id);
    setText(note.text);

    if(note.text === "") {
      textRef.current.focus();
    }

    computeLengthAndTrim();
    props.onTextChanged(note.text.length);

  }, [])



  function onFinishedChangingText(e) {
    const note = notesStore.getNoteById(id);
    let text = e.target.innerText.trim();
    if(note.text !== text) {
      note.text = text;
      note.updatedTime = nowSec();
      notesStore.saveNote(note.id);
    }
  }

  function onChange(e) {
    computeLengthAndTrim();
  }

  function computeLengthAndTrim(userStore = false) {
    if(textRef.current.innerText.length > props.max) {
      textRef.current.innerText = textRef.current.innerText.substring(0, props.max);
    }
    props.onTextChanged(textRef.current.innerText.length);
  }


  return <div className={"content"}>
      <p
          ref={textRef}
          className={"plaintext"}
          contentEditable={true}
          suppressContentEditableWarning={true}
          placeholder={t("Note page. Plaintext. Text placeholder")}
          onBlur={(e) => onFinishedChangingText(e)}
          onInput={(e) => onChange(e)}
          onChange={() => alert(1)}
      >
        {text}
      </p>
    </div>
}
