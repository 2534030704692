import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import {Routes, Route, Outlet, Link, Navigate, useNavigate} from "react-router-dom";
import { observer } from "mobx-react"
import {NOTE_TYPE, URL_SYNC_NOTES} from "../../utils/const";
import {getUserPhotoURL, newRandomItemId, nowSec, requestPost, syncNotes} from "../../utils/common"
import { useTranslation } from 'react-i18next';
import {syncingStore} from "../../stores/SyncingStore";
import * as amplitude from '@amplitude/analytics-browser';

export const NotesPage = observer(({userStore, notesStore}) => {

  let pageRef = useRef();
  const { t } = useTranslation();

  //const [visible, setVisible] = useState(props.visible);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showCreateSelection, setShowCreateSelection] = useState(false);

  const navigate = useNavigate();

  function onCreatePlaintext() {
    amplitude.track('Click: Create Plaintext');
    const noteId = Math.random();
    const now = nowSec();
    notesStore.addNote({
      created: true,
      id: noteId,
      type: NOTE_TYPE.PLAINTEXT,
      title: "",
      userId: userStore.firebaseUser.uid,
      createdTime: now,
      updatedTime: now,

      text: "",
    });

    navigate(`/note/${noteId}`);
  }

  function onCreateChecklist() {
    amplitude.track('Click: Create Checklist');
    const noteId = Math.random();
    const now = nowSec();
    notesStore.addNote({
      created: true,
      id: noteId,
      type: NOTE_TYPE.CHECKLIST,
      title: "",
      userId: userStore.firebaseUser.uid,
      createdTime: now,
      updatedTime: now,

      items: [{
        id: newRandomItemId(),
        updatedTime: now,
        done: false,
        text: ""
      }]
    });

    navigate(`/note/${noteId}`);
  }

  function onRemoveNote(e, id) {
    amplitude.track('Click: Remove note');
    e.stopPropagation();
    e.preventDefault();
    notesStore.removeNote(id);
  }

  async function onSync() {
    amplitude.track('Click: Sync');
    await syncNotes();
  }

  function onScroll(e) {
    //console.log("!!!", e.target.scrollTop);
    setIsScrolled(e.target.scrollTop !== 0);
  }

  function onCreate() {
    setShowCreateSelection(!showCreateSelection);
  }


  let sortedNotes = notesStore.notes.map(note => note);
  sortedNotes.sort((a, b) => a.updatedTime > b.updatedTime ? -1 : 1);

  if(!userStore.firebaseUser)  return null;

  return <div ref={pageRef} className={`page notes-page ${isScrolled ? "scrolled" : ""}`} onScroll={(e) => onScroll(e)}>
    <div className={"header"}>
      <div className={"title"}>{t("Notes page. Title")}</div>
      <Link to={!userStore.firebaseUser.isAnonymous ? "/profile" : "/"}>
        <div className={`profile ${userStore.firebaseUser.isAnonymous ? "anonymous" : ""}`}>
          {!userStore.firebaseUser.isAnonymous && <img src={getUserPhotoURL()} align={"left"} width={"100%"} height={"100%"} referrerPolicy="no-referrer"/>}
        </div>
      </Link>

    </div>

    <div className={"content"}>
      {notesStore.notes.length === 0 && <div className={"empty"}>
            <div className={"icon"}></div>
            <div className={"text"}>{t("Notes page. No notes text")}</div>
          </div>}
      {
        sortedNotes.map(note => {
          if(note.type === NOTE_TYPE.PLAINTEXT && !note.deleted) {
            return <Link key={note.id} to={`/note/${note.id}`}>
              <div className={"note"}>
                {note.title !== "" && <div className={"title"}>{note.title}</div>}
                <div className={"payload"}>{note.text}</div>
                <button className={"remove"} onClick={(e) => onRemoveNote(e, note.id)}></button>
              </div>
            </Link>
          } else if(note.type === NOTE_TYPE.CHECKLIST && !note.deleted) {
            return <Link key={note.id} to={`/note/${note.id}`}>
              <div className={"note"}>
                {note.title !== "" && <div className={"title"}>{note.title}</div>}
                <div className={"payload"}>
                  <div className={"list-item"}>
                    {note.items.map(item => <div
                        key={item.id}
                        className={"text"}
                        data-done={item.done}
                    >{item.text}
                    </div>)}
                  </div>
                </div>
                <button className={"remove"} onClick={(e) => onRemoveNote(e, note.id)}></button>
              </div>
            </Link>
          }
        })
      }
    </div>
    <button className={`main create ${showCreateSelection ? "close" : ""}`} onClick={onCreate}>
      {showCreateSelection && <button onClick={onCreateChecklist} className={"main create-checklist fadeIn"}></button>}
      {showCreateSelection && <button onClick={onCreatePlaintext} className={"main create-plaintext fadeIn"}></button>}
    </button>
    {!showCreateSelection && <button className={`main update ${syncingStore.ongoing ? "loading" : ""} ${notesStore.hasChanges ? "has-changes" : ""}`} onClick={onSync}></button>}

  </div>
});
