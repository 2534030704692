export const NOTE_TYPE = {
  PLAINTEXT: "plaintext",
  CHECKLIST: "checklist"
};

export const RESPONSE_ERRORS = {
  DEVICE_ID_REQUIRED: "device-id-required",
  DEVICE_ID_OR_USER_ID_REQUIRED: "device-id-or-user-id-required",
  WRONG_DEVICE_ID: "wrong-device-id",
  DEVICE_PAIRED_WITH_OTHER_USER: "device-paired-with-other-user",
  NO_PAIRED_DEVICES: "no-paired-devices",
  NEED_AUTH: "need-auth"
}

export const USE_EMULATOR = window.location.host.split(':')[0] === 'localhost' ? true : false;

export const FUNC_REGION = process.env.REACT_APP_region;

export const PLAINTEXT_MAX = 500;

export const URL_SYNC_NOTES =
    USE_EMULATOR ?
        `https://39df-178-158-252-51.eu.ngrok.io/${process.env.REACT_APP_projectId}/${FUNC_REGION}/requestSyncUserNotes` :
        `https://${FUNC_REGION}-${process.env.REACT_APP_projectId}.cloudfunctions.net/requestSyncUserNotes`;

export const ALERT_TYPE = {
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
  QUESTION: "question",
};

export const AMPLITUDE = {
  APIKEY: "372c2a4957b2538ae952cdce2ae049c6",
  USE_FOR_HOST: [
    "localhost:3000",
    "2do.ooo",
    "www.2do.ooo"
  ]
};
