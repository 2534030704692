import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Main page. Move to account. Text": "If you switch this account, all current notes will be lost.<br/><br/>Continue?",
      "Main page. Move to account. Button Ok": "OK",
      "Main page. Move to account. Button Cancel": "Cancel",
      "Main page. Title": "Hi!",
      "Main page. Subtitle": "If you are constantly forgetting everything – this app is just for you!",
      "Main page. Please auth": "Sign in to keep save all your notes:",
      "Main page. Login google": "Login with Google",
      "Main page. Login apple": "Login with Apple",
      "Main page. Skip": "Skip",
      ///////////////////////////////////////////////
      "Notes page. Title": "My notes",
      "Notes page. No notes text": "Create your first list or note",
      "Notes page. Add plaintext": "Note",
      "Notes page. Add checklist": "List",
      ///////////////////////////////////////////////
      "Note page. Plaintext. Header info": "Used {{textLength}} of {{max}}",
      "Note page. Plaintext. Title placeholder": "Title...",
      "Note page. Plaintext. Text placeholder": "Note text...",
      ///////////////////////////////////////////////
      "Note page. Checklist. Header info": "Done {{countDone}} of {{countTotal}}",
      "Note page. Checklist. Title placeholder": "Title...",
      "Note page. Checklist. Add item": "Add one more item",
      "Note page. Checklist. Item placeholder": "Item text...",
      ///////////////////////////////////////////////
      "QRCode page. Error alert. Text": "Error: {{error}}",
      "QRCode page. Text": "In order to get access to your notes on the smart watch, connect it with a smartphone",
      "QRCode page. Button OK": "OK",
      "QRCode page. Button Cancel": "Cancel",
      "QRCode page. Error alert. Button": "OK",
      "QRCode page. Pair prompt. Text": "Yay! Your watch is now connected to your smartphone. You will be able to interact with notes from both devices after the synchronization is complete.",
      "QRCode page. Pair prompt. Button": "OK",
      "QRCode page. Repair. Text": "It seems this watch is already tied with another account. In case of its switching, all current notes will be lost and new ones will appear.",
      "QRCode page. Repair. Button continue": "Pair",
      "QRCode page. Repair. Button cancel": "Cancel",
      "QRCode page. Error wrong device id": "Error! There is no device with this device ID :(",
      ///////////////////////////////////////////////
      "Sync error": "Connection error. Check your internet connection"
    }
  },

  ua: {
    translation: {
      "Main page. Move to account. Text": "У випадку переключення цього аккаунту поточні нотатки будуть втрачені.<br/><br/>Продовжити?",
      "Main page. Move to account. Button Ok": "Так",
      "Main page. Move to account. Button Cancel": "Відмінити",
      "Main page. Title": "Вітаємо!",
      "Main page. Subtitle": "Якщо ви постійно все забуваєте, цей додаток саме для вас",
      "Main page. Please auth": "Авторизуйтеся, щоб всі ваші нотатки збереглися:",
      "Main page. Login google": "Авторизація Google",
      "Main page. Login apple": "Авторизація Apple",
      "Main page. Skip": "Пропустити",
      ///////////////////////////////////////////////
      "Notes page. Title": "Мої нотатки",
      "Notes page. No notes text": "Додайте свій перший список або нотатку",
      "Notes page. Add plaintext": "Нотатка",
      "Notes page. Add checklist": "Список",
      ///////////////////////////////////////////////
      "Note page. Plaintext. Header info": "Використано {{textLength}} з {{max}}",
      "Note page. Plaintext. Title placeholder": "Назва...",
      "Note page. Plaintext. Text placeholder": "Введіть текст нотатки...",
      ///////////////////////////////////////////////
      "Note page. Checklist. Header info": "Виконано {{countDone}} з {{countTotal}}",
      "Note page. Checklist. Title placeholder": "Назва...",
      "Note page. Checklist. Add item": "Додати ще один пункт",
      "Note page. Checklist. Item placeholder": "Введіть текст...",
      ///////////////////////////////////////////////
      "QRCode page. Error alert. Text": "Помилка: {{error}}",
      "QRCode page. Text": "Підключіть свій телефон до цього пристрою щоб дивитись свої нотатки на годиннику",
      "QRCode page. Button OK": "ОК",
      "QRCode page. Button Cancel": "Відмінити",
      "QRCode page. Error alert. Button": "OK",
      "QRCode page. Pair prompt. Text": "Вітаємо! Ви прив'язали годинник. Тепер, після синхронізації, ви будете бачити на ньому всі нотатки зі свого акаунта",
      "QRCode page. Pair prompt. Button": "OK",
      "QRCode page. Repair. Text": "Здається цей годинник вже прив'язаний до іншого аккаунту. У випадку його переключення, поточні нотатки будуть втрачені та з'являться нові.",
      "QRCode page. Repair. Button continue": "Прив'язати",
      "QRCode page. Repair. Button cancel": "Відмінити",
      "QRCode page. Error wrong device id": "Помилка! Такий пристрій в системі не зареєстрований :(",
      ///////////////////////////////////////////////
      "Sync error": "Виникла помилка з'єднання, перевірте інтернет"
    }
  }
};

//console.log("navigator!!!", navigator);

resources.ru = resources.ua;

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
      detection: {
        order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain']
      },
      fallbackLng: 'en',
      resources,
      //lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option

      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });

export default i18n;
