import React, {useEffect, useRef, useState} from "react";
import {Routes, Route, Outlet, Link, Navigate, useNavigate} from "react-router-dom";
import "./style.scss";
import { observer } from "mobx-react"
import { getAuth, signOut } from "firebase/auth";
import {notesStore} from "../../stores/NotesStore";
import {getUserPhotoURL} from "../../utils/common";

export const ProfilePage = observer(({userStore}) => {

  const navigate = useNavigate();

  async function onExit() {

    notesStore.clearStorage();
    await signOut(getAuth());
    navigate("/");
  }

  if(!(!!userStore.firebaseUser && !userStore.firebaseUser.isAnonymous)) return null;

  return <div className={"page profile-page"}>
    <div className={"header"}>
      <div className={"back"} onClick={() => navigate(-1)}></div>
    </div>
    <div className={"avatar"}>
      <img src={getUserPhotoURL()} width={"100%"} height={"100%"} referrerPolicy="no-referrer"/>
    </div>
    <div className={"email"}>{userStore.firebaseUser && userStore.firebaseUser.email}</div>
    <Link to="/">
      <button className={"exit"} onClick={() => onExit()}>Вийти</button>
    </Link>
  </div>
})
