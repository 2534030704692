import {makeObservable, observable} from "mobx"

class SyncingStore {
  ongoing = false;

  constructor() {
    makeObservable(this, {
      ongoing: observable
    })
  }

  start() {
    this.ongoing = true;
  }

  stop() {
    this.ongoing = false;
  }
}

export const syncingStore = new SyncingStore();
