import {makeObservable, observable, autorun} from "mobx"
import {NOTE_TYPE} from "../utils/const";

class NotesStore {
  notes = [];
  hasChanges = false;

  constructor() {
    makeObservable(this, {
      notes: observable,
      hasChanges: observable
    });

    this.loadNotes();

    autorun(() => {
      /*this.notes.sort((a,b) => {
        return a.updatedTime > b.updatedTime ? 1 : -1;
      });*/
      /*for(let i=0;i<this.notes.length;i++) {
        if(this.notes[i].type === NOTE_TYPE.CHECKLIST) {
          for(let j=0;j<this.notes[i].items.length;j++) {
          }
        }
      }*/
    });

  }

  fillStore(notesFromCloud) {
    // затираем все заметки в базе
    for(let i=0;i<this.notes.length;i++) {
      localStorage.removeItem(`note_${this.notes[i].id}`);
    }
    // удаляяем заметки из стора
    this.notes.splice(0, this.notes.length);

    // затираем список id в сторадже
    this.saveNotesIds();

    // добавляем новые заметки в стор и сторадж
    notesFromCloud.forEach(note => this.addNote(note));

  }


  getNoteById(id) {
    let index = this.notes.findIndex((p) => p.id + "" === id + "");
    if(index !== -1) {
      return this.notes[index];
    }
    //alert(`getNoteById(${id})===null`);
    return null;
  }

  saveNote(id) {
    for(let i=0;i<this.notes.length;i++) {
      const note = this.notes[i];
      if(note.id === id) {
        const string = JSON.stringify(note);
        localStorage.setItem(`note_${note.id}`, string);
        this.setHasChanges(true);
        break;
      }
    }
  }

  setHasChanges(val) {
    localStorage.setItem('notesHasChanges', val);
    this.hasChanges = val;
  }

  saveNotes() {
    for(let i=0;i<this.notes.length;i++) {
      this.saveNote(this.notes[i].id);
    }
    this.saveNotesIds();
  }

  saveNotesIds() {
    let noteIds = [];
    for(let i=0;i<this.notes.length;i++) {
      noteIds.push(this.notes[i].id);
    }
    localStorage.setItem('noteIds', JSON.stringify(noteIds));
  }

  loadNotes() {
    const noteIdsString = localStorage.getItem("noteIds");
    let noteIds = [];
    if(noteIdsString) {
      noteIds = JSON.parse(noteIdsString);
    }

    for(let i=0;i<noteIds.length;i++) {
      const noteString = localStorage.getItem(`note_${noteIds[i]}`);
      if(noteString) {
        this.notes.push(JSON.parse(noteString));
      }
    }

    //alert(localStorage.getItem("notesHasChanges"));
    this.setHasChanges(localStorage.getItem("notesHasChanges") === 'true');
  }

  addNote(data) {
    this.notes.push(data);
    this.saveNote(data.id);
    this.saveNotesIds();
  }

  removeNote(id) {
    for(let i=0;i<this.notes.length;i++) {
      if(this.notes[i].id === id) {
        //this.notes.splice(i, 1);
        this.notes[i].deleted = true;
        break;
      }
    }
    this.saveNote(id);
    //this.saveNotesIds();
    //localStorage.removeItem(`note_${id}`);
  }

  clearStorage() {
    const noteIdsString = localStorage.getItem("noteIds");
    let noteIds = [];
    if(noteIdsString) {
      noteIds = JSON.parse(noteIdsString);
    }

    for(let i=0;i<noteIds.length;i++) {
      localStorage.removeItem(`note_${noteIds[i]}`);
    }

    localStorage.removeItem("noteIds");

    this.notes = [];
  }

}





export const notesStore = new NotesStore();
