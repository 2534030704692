import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import {Routes, Route, Outlet, Link, useParams} from "react-router-dom";
import {notesStore} from "../../../stores/NotesStore";
import {caretToEnd, clone, nowSec} from "../../../utils/common";
import { observer } from "mobx-react"
import {useTranslation} from "react-i18next";

export const Item = observer(({
    index,
    focused,
    item,
    note,
    onAddItem,
    addMultiItems,
    onChangeFocused
}) => {

  //const [visible, setVisible] = useState(props.visible);

  let textRef = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    if(focused) {
      if(item.text === "") {
        textRef.current.focus();
      } else {
        caretToEnd(textRef.current);
      }
    }
  }, [focused])



  function onFinishedChangingText(e, addNewItem= false) {
    let text = e.target.innerText.replace(/\n/g, " ").trim();
    if(text !== item.text) {
      const now = nowSec();
      item.text = text;
      item.updatedTime = now;
      note.updatedTime = now;
      notesStore.saveNote(note.id);
    }

    if(addNewItem) {
      onAddItem(index);
    }
  }

  function changeDone() {
    const now = nowSec();
    item.done = !item.done;
    item.updatedTime = now;
    note.updatedTime = now;
    notesStore.saveNote(note.id);
  }

  function onRemove() {
    for(let i=0;i<note.items.length;i++) {
      if(note.items[i].id === item.id) {
        note.items.splice(i, 1);
        note.updatedTime = nowSec();
        notesStore.saveNote(note.id);
        break;
      }
    }
  }

  const onKeyDown = (e) => {

    if(e.keyCode === 13) {
      onFinishedChangingText(e, true);
      setTimeout(() => {
      });
    } else if(e.keyCode === 8) {
      if(e.target.innerText === "") {
        setTimeout(() => {
          onRemove();
          onChangeFocused(index-1);
        }, 50)
      }
    }
    e.stopPropagation();
  }

  const onKeyUp = (e) => {

    if(e.keyCode === 13) {
      e.target.innerText = "";
    }
    e.stopPropagation();
  }

  const onPaste = (e) => {
    e.preventDefault();
    let paste = (e.clipboardData || window.clipboardData).getData("text");
    let strings = paste.split('\n');
    if(strings.length === 1) {
      onFinishedChangingText(e, true);
    } else {
      const now = nowSec();
      item.text = strings[0];
      item.updatedTime = now;
    }
    strings.splice(0, 1);
    for(let i=0;i<strings.length;i++) {
      strings[i] = strings[i].replace("\r", "").trim();
    }
    addMultiItems(strings, index);
  }

  let onFocused = (e) => {
    onChangeFocused(index);
  }


  return <div className={"list-item"} data-selected={focused} data-done={item.done}>
    <div className={"checkbox"} onClick={changeDone} ></div>
    <div
        className={"text"}
        contentEditable={true}
        suppressContentEditableWarning={true}
        ref={textRef}
        onFocus={onFocused}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onPaste={onPaste}
        onBlur={(e) => onFinishedChangingText(e, false)}
        placeholder={t("Note page. Checklist. Item placeholder")}
    >
      {item.text}
    </div>
    <div onClick={onRemove} className={"remove"}></div>
  </div>
})
