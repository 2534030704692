import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import {Routes, Route, Outlet, Link, Navigate, useNavigate} from "react-router-dom";
import { getAuth, GoogleAuthProvider, signInWithPopup, linkWithPopup, signInWithCredential } from "firebase/auth";
import {userStore} from "../../stores/UserStore";
import {observer} from "mobx-react";
import {syncNotes} from "../../utils/common";
import {Alert} from "../alert/Alert";
import {notesStore} from "../../stores/NotesStore";
import {popupStore} from "../../stores/PopupStore";
import {ALERT_TYPE} from "../../utils/const";
import {useTranslation} from "react-i18next";


export const MainPage = observer(({userStore}) => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  function onLogin() {

    console.log(getAuth().currentUser);
    linkWithPopup(getAuth().currentUser, new GoogleAuthProvider())
      .then((result) => {
        userStore.setFirebaseUser(getAuth().currentUser);
      })
      .catch((error) => {
        if(notesStore.notes.length > 0) {
          popupStore.show(
            ALERT_TYPE.QUESTION,
            t("Main page. Move to account. Text"),
            [{
                text: t("Main page. Move to account. Button Ok"),
                onClick: () => onClearConfirm(GoogleAuthProvider.credentialFromError(error))
              },
              {
                text: t("Main page. Move to account. Button Cancel"),
                onClick: onClearReject
              }])
        } else {

          _signInWithCredential(GoogleAuthProvider.credentialFromError(error));
        }
      });
  }

  function _signInWithCredential(credential) {
    console.log(credential);
    signInWithCredential(getAuth(), credential)
        .then((result) => {
          console.log("signInWithCredential OK");
          syncNotes();
        })
        .catch((error) => {
          console.log("signInWithCredential ERROR", error);
        });
  }

  function onClearConfirm(credential) {

    notesStore.clearStorage();
    _signInWithCredential(credential);
    popupStore.hide();
  }

  function onClearReject() {
    popupStore.hide();
  }

  useEffect(() => {
    if(!!userStore.firebaseUser) {
      if(!userStore.firebaseUser.isAnonymous) {
        navigate("/notes");
      } else {
      }
    }  else {
    }
  }, [userStore.firebaseUser, userStore.isAnonymous])

  if(!userStore.firebaseUser)  return null;

  return <div className={"page main-page"}>
    <img src={"/img/2do.png"} className={"logo"}/>
    <div className={"title"}>{t("Main page. Title")}</div>
    <div className={"subtitle"}>{t("Main page. Subtitle")}</div>
    <div className={"please-auth"}>{t("Main page. Please auth")}</div>
    <button onClick={() => onLogin()}>{t("Main page. Login google")}</button>
    {/*<button>{t("Main page. Login apple")}</button>*/}
    <Link to="/notes"><div className={"skip"}>{t("Main page. Skip")}</div></Link>

  </div>
});
