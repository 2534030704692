import {makeObservable, observable} from "mobx"

class UserStore {
  firebaseUser = null;
  isAnonymous = true;
  pairedDevices = [];

  constructor() {
    makeObservable(this, {
      firebaseUser: observable,
      isAnonymous: observable,
      pairedDevices: observable
    })

    let pd = localStorage.getItem("pairedDevices");
    if(!!pd) {
      this.pairedDevices = JSON.parse(pd);
    }
  }

  isDevicePaired(deviceId) {
    for(let i=0;i<this.pairedDevices.length;i++) {
      if(this.pairedDevices[i] === deviceId)
        return true;
    }
    return false;
  }

  setFirebaseUser(u) {
    this.firebaseUser = u;
    this.isAnonymous = this.firebaseUser ? this.firebaseUser.isAnonymous : false;
  }

  setPairedDevices(pd) {
    this.pairedDevices = pd;
    localStorage.setItem("pairedDevices", JSON.stringify(pd));
  }
}

export const userStore = new UserStore();
