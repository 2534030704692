import React, {useEffect, useRef, useState, useTransition} from "react";
import "./style.scss";
import {Routes, Route, Outlet, Link, useParams} from "react-router-dom";
import {notesStore} from "../../../stores/NotesStore";
import {Item} from "./Item";
import {clone, newRandomItemId, nowSec} from "../../../utils/common";
import { observer } from "mobx-react"
import {useTranslation} from "react-i18next";

export const ChecklistNote = observer(({note}) => {

  //const [visible, setVisible] = useState(props.visible);
  const [focusedItemIndex, setFocusedItemIndex] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {

  }, [])

  function getEmptyItem() {
    return {
      id: newRandomItemId(),
      updatedTime: 0,
      done: false,
      text: ""
    }
  }

  function onAddItem(afterIndex = null) {
    const now = nowSec();
    let addToPos = afterIndex === null ? note.items.length : afterIndex+1;
    let newItem = getEmptyItem();
    newItem.updatedTime = nowSec();
    note.items.splice(
      addToPos,
      0,
      newItem
    );
    note.updatedTime = now;
    notesStore.saveNote(note.id);
    setFocusedItemIndex(addToPos);
  }

  function addMultiItems(strings, afterIndex) {
    const now = nowSec();
    let addToPos = afterIndex+1;
    for(let i=0;i<strings.length;i++) {
      let newItem = getEmptyItem();
      newItem.updatedTime = now;
      newItem.text = strings[i];
      note.items.splice(
          addToPos,
          0,
          newItem
      );
      addToPos++;
    }

    note.updatedTime = now;
    notesStore.saveNote(note.id);
    setFocusedItemIndex(addToPos-1);
  }

  function onChangeFocused(newFocusItemIndex) {
    setFocusedItemIndex(newFocusItemIndex);
  }

  if(!note || !note.items)
    return null;

  return <div className={"content"}>
    {note.items.map((item, index) => <Item
        key={item.id}
        index={index}
        focused={index === focusedItemIndex}
        item={item}
        note={note}
        onAddItem={onAddItem}
        addMultiItems={addMultiItems}
        onChangeFocused={(index) => onChangeFocused(index)}
    />)}
    <div onClick={() => onAddItem()} className={"add-item"}>{t("Note page. Checklist. Add item")}</div>
  </div>
})
