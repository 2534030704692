import {getFunctions, httpsCallable} from "firebase/functions";
import {ALERT_TYPE, FUNC_REGION, URL_SYNC_NOTES} from "./const";
import {userStore} from "../stores/UserStore";
import {notesStore} from "../stores/NotesStore";
import {popupStore} from "../stores/PopupStore";
import i18n from "../i18n"
import {syncingStore} from "../stores/SyncingStore";

export function clone(data) {
  return JSON.parse(JSON.stringify(data));
}

export async function syncNotes(callback = null) {
  syncingStore.start();
  try {
    const data = await requestPost(URL_SYNC_NOTES, {
      userId: userStore.firebaseUser.uid,
      notes: notesStore.notes
    });

    if(!!data.notes) {
      notesStore.fillStore(data.notes);
    }

    notesStore.setHasChanges(false);

    if(callback) callback();
  } catch (e) {
    popupStore.show(ALERT_TYPE.ERROR, i18n.t("Sync error"));
  }
  syncingStore.stop();
}

export function getUserPhotoURL() {
  return userStore.firebaseUser.providerData[0] ? userStore.firebaseUser.providerData[0].photoURL : null;
}


export function ffunctions() {
  return getFunctions(window.firebaseApp, FUNC_REGION);
}

export function newRandomItemId() {
  return Math.round(Math.random() * 1000000) + "";
}

export function nowSec() {
  return Math.round(new Date().getTime() / 1000);
}

export async function requestPost(url, data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return await response.json(); // parses JSON response into native JavaScript objects
}

export function caretToEnd(el) {
  let selection = window.getSelection()
  let range = createRange(el, {
    count: el.innerHTML.length
  })
  if (range) {
    range.collapse(false)
    selection.removeAllRanges()
    selection.addRange(range)
  }
}

function createRange(node, chars, range) {
  if (!range) {
    range = document.createRange()
    range.selectNode(node)
    range.setStart(node, 0)
  }
  if (chars.count === 0) {
    range.setEnd(node, chars.count)
  } else if (node && chars.count > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.length < chars.count) {
        chars.count -= node.textContent.length
      } else {
        range.setEnd(node, chars.count)
        chars.count = 0
      }
    } else {
      for (var lp = 0; lp < node.childNodes.length; lp++) {
        range = createRange(node.childNodes[lp], chars, range)
        if (chars.count === 0) {
          break
        }
      }
    }
  }
  return range
}
