import {makeObservable, observable} from "mobx"

class LoaderStore {
  visible = false;
  fadeClass = "";

  constructor() {
    makeObservable(this, {
      visible: observable,
      fadeClass: observable
    })
  }

  show() {
    this.visible = true;
    this.fadeClass = "fadeIn";
  }

  hide() {
    this.fadeClass = "fadeOut";
    setTimeout(() => {
      this.visible = false;
    }, 500)
  }
}

export const loaderStore = new LoaderStore();
