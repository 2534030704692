import * as React from "react";
import {Routes, Route, Outlet, Link, Navigate, useLocation} from "react-router-dom";
import {MainPage} from "./components/main-page/MainPage";
import "./App.scss";
import {NotesPage} from "./components/notes-page/NotesPage";
import {ProfilePage} from "./components/profile-page/ProfilePage";
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import {useEffect, useState} from "react";
import {userStore} from "./stores/UserStore";
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import {notesStore} from "./stores/NotesStore";
import {popupStore} from "./stores/PopupStore";
import {NotePage} from "./components/note-page/NotePage";
import {QRCodePage} from "./components/qrcode-page/QRCodePage";
import {ffunctions} from "./utils/common";
import {connectFunctionsEmulator, httpsCallable} from "firebase/functions";
import {ALERT_TYPE, AMPLITUDE, USE_EMULATOR} from "./utils/const";
import {Alert} from "./components/alert/Alert";
import {observer} from "mobx-react";
import './i18n';
import {loaderStore} from "./stores/LoaderStore";
import i18n from "./i18n";
import {syncingStore} from "./stores/SyncingStore";
import * as amplitude from '@amplitude/analytics-browser';



window.firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
});

if(USE_EMULATOR)
  connectFunctionsEmulator(ffunctions(), "localhost", 5001);


if(!!AMPLITUDE.USE_FOR_HOST.find(el => el === document.location.host)) {
  amplitude.init(AMPLITUDE.APIKEY, undefined, { defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true }});
}

function setFS() {
  const fs = window.screen.height / 812;
  const html = document.querySelector('html');
  html.style.fontSize = fs + 'px';
  html.setAttribute('fs', fs+'');
}

function resize() {
  const h_w = window.screen.height / window.innerWidth;
  const html = document.querySelector('html');

  if(h_w > 1.3) { //портрет
    setFS();
    html.removeAttribute('too-wide');
    html.removeAttribute('album');
  } else if (h_w > 0.75) { // портрет но открыта клавиатура
    html.setAttribute('too-wide', "1");
    html.removeAttribute('album');
  } else { // альбом
    setFS();
    html.removeAttribute('too-wide');
    html.setAttribute('album', "1");
  }

};




export const App = observer( () => {

  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState("pageIn");



  useEffect(() => {
    if (location.pathname !== displayLocation.pathname) {
      setTransitionStage("pageOut");
    }
  }, [location, displayLocation]);

  window.addEventListener('resize', () => setTimeout(() => resize(), 50));
  resize();

  useEffect(() => {
    const auth = getAuth();

    onAuthStateChanged(auth, async (user) => {
      console.log('onAuthStateChanged', user);
      userStore.setFirebaseUser(user);
      if(!user) {
        signInAnonymously(auth)
            .then((userCredential) => {
              const user = userCredential.user;
              // ...
            })
            .catch((error) => {
              console.log(error);
              const errorCode = error.code;
              const errorMessage = error.message;
              // ...
            });
      } else {
        let result = null;
        try {
          result = await httpsCallable(ffunctions(), "apiGetUserPairedDevices")()
        } catch (e) {
          popupStore.show(ALERT_TYPE.ERROR, i18n.t("Sync error"));
        }

        if(!!result.data.devices) {
          userStore.setPairedDevices(result.data.devices);
        }
      }

    });

    new firebaseui.auth.AuthUI(auth)

  }, [])


  /*if(userStore.firebaseUser === null) {
    return null;
  }*/

  return (
      <div
           className={`App ${transitionStage}`}
           onAnimationEnd={() => {
             if (transitionStage === "pageOut") {
               setTransitionStage("pageIn");
               setDisplayLocation(location);
             }
           }}
      >
        {popupStore.visible && <Alert popupStore={popupStore}/>}
        {loaderStore.visible && <Loader/>}
        <div id={"firebaseui-auth-container"}></div>
          <Routes location={displayLocation}>
            <Route path="/" element={<MainPage userStore={userStore}/>}></Route>
            <Route path="/profile" element={<ProfilePage userStore={userStore}/>}></Route>
            <Route path="/notes" element={<NotesPage userStore={userStore} notesStore={notesStore} syncingStore={syncingStore}/>}></Route>
            <Route path="/note/:id" element={<NotePage notesStore={notesStore}/>}></Route>
            <Route path="/q/:deviceId/:partNumber" element={<QRCodePage userStore={userStore} popupStore={popupStore}/>}></Route>
          </Routes>
      </div>
  );
});

export const Loader = observer(() => {
  return <div className={`loader-wrapper ${loaderStore.fadeClass}`}>
    <div className={`loader`}>
      <div className="lds-dual-ring"></div>
    </div>
  </div>
});

class AAA extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      width: 0
    }
  }

  setFS() {
    const fs = window.screen.height / 812;
    const html = document.querySelector('html');
    html.style.fontSize = fs + 'px';
    html.setAttribute('fs', fs+'');
  }

  resize() {
    const h_w = window.screen.height / window.innerWidth;
    this.setState({
      height: window.screen.height,
      width: window.innerWidth
    });
    //alert(window.screen.height);
    const html = document.querySelector('html');

    if(h_w > 1.3) { //портрет
      this.setFS();
      html.removeAttribute('too-wide');
      html.removeAttribute('album');
    } else if (h_w > 0.75) { // портрет но открыта клавиатура
      html.setAttribute('too-wide', "1");
      html.removeAttribute('album');
    } else { // альбом
      this.setFS();
      html.removeAttribute('too-wide');
      html.setAttribute('album', "1");
    }

  };

  componentDidMount() {

    window.addEventListener('resize', () => setTimeout(() => this.resize(), 50));

    this.resize();
  }

  render() {
    let activePage = 0;
    if(window.location.pathname[0] === '/' && window.location.pathname.length > 1) {
      activePage = parseInt(window.location.pathname.substring(1));
    }
    return <div className="App">
      <div className={"bg"}></div>
      {activePage === 0 && <div className={"page main-page"}>
        <img src={"/img/2do.png"}/>
        <div className={"title"}>Вітаємо!</div>
        <div className={"subtitle"}>Якщо ви постійно все забуваєте цей додаток самедля вас</div>
        <div className={"please-auth"}>Авторизуйтеся щоб всі ваші нотатки збереглися</div>
        <button>Авторизація Google</button>
        <button>Авторизація Apple</button>
        <div className={"skip"}>Пропустити</div>
      </div>}

      {activePage === 1 && <div className={"page notes-page"}>
        <div className={"header"}>
          <div className={"title"}>Мої нотатки</div>
          <div className={"profile"}></div>
        </div>

        <div className={"content"}>
          {/*<div className={"empty"}>
            <div className={"icon"}></div>
            <div className={"text"}>Додайте свій перший Список чи текстову Нотатку</div>
          </div>*/}
          <div className={"note"}>
            <div className={"title"}>Мої думки тихі {this.state.height}</div>
            <div className={"payload"}>
              Може, як ви прочитаєте цю книжку, то вам відкриється: сила і слабкість, вічне і дріб'язок, життя і смерть. І, може, прочитавши цей твір, ви скажете, що луччє: пизда чи вєлосіпєд. P.S. Хоча ми з вами здогадуємося, що...
              Може, як ви прочитаєте цю книжку, то вам відкриється: сила і слабкість, вічне і дріб'язок, життя і смерть. І, може, прочитавши цей твір, ви скажете, що луччє: пизда чи вєлосіпєд. P.S. Хоча ми з вами здогадуємося, що...
            </div>
            <div className={"bottom"}></div>
          </div>
          <div className={"note"}>
            <div className={"title"}>Мої думки тихі {this.state.height}</div>
            <div className={"payload"}>
              <div className={"list-item"}>
                <div className={"text"}>Може, як ви прочитаєте цю книжку, то вам відкриється: сила і слабкість</div>
                <div className={"text"}>Може, як ви прочитаєте</div>
                <div className={"text"}>Може, як ви прочитаєте</div>
              </div>
            </div>
          </div>
          <div className={"note"}>
            <div className={"title"}>Мої думки тихі</div>
            <div className={"payload"}>
              Може, як ви прочитаєте цю книжку, то вам відкриється: сила і слабкість, вічне і дріб'язок, життя і смерть. І, може, прочитавши цей твір, ви скажете, що луччє: пизда чи вєлосіпєд. P.S. Хоча ми з вами здогадуємося, що...
              Може, як ви прочитаєте цю книжку, то вам відкриється: сила і слабкість, вічне і дріб'язок, життя і смерть. І, може, прочитавши цей твір, ви скажете, що луччє: пизда чи вєлосіпєд. P.S. Хоча ми з вами здогадуємося, що...
            </div>
          </div>
          <div className={"note"}>
            <div className={"title"}>Мої думки тихі {this.state.height}</div>
            <div className={"payload"}>
              Може, як ви прочитаєте цю книжку, то вам відкриється: сила і слабкість, вічне і дріб'язок, життя і смерть. І, може, прочитавши цей твір, ви скажете, що луччє: пизда чи вєлосіпєд. P.S. Хоча ми з вами здогадуємося, що...
              Може, як ви прочитаєте цю книжку, то вам відкриється: сила і слабкість, вічне і дріб'язок, життя і смерть. І, може, прочитавши цей твір, ви скажете, що луччє: пизда чи вєлосіпєд. P.S. Хоча ми з вами здогадуємося, що...
            </div>
          </div>
          <div className={"note"}>
            <div className={"title"}>Мої думки тихі</div>
            <div className={"payload"}>
              Може, як ви прочитаєте цю книжку, то вам відкриється: сила і слабкість, вічне і дріб'язок, життя і смерть. І, може, прочитавши цей твір, ви скажете, що луччє: пизда чи вєлосіпєд. P.S. Хоча ми з вами здогадуємося, що...
              Може, як ви прочитаєте цю книжку, то вам відкриється: сила і слабкість, вічне і дріб'язок, життя і смерть. І, може, прочитавши цей твір, ви скажете, що луччє: пизда чи вєлосіпєд. P.S. Хоча ми з вами здогадуємося, що...
            </div>
          </div>
          <div className={"note"}>
            <div className={"title"}>Мої думки тихі</div>
            <div className={"payload"}>
              Може, як ви прочитаєте цю книжку, то вам відкриється: сила і слабкість, вічне і дріб'язок, життя і смерть. І, може, прочитавши цей твір, ви скажете, що луччє: пизда чи вєлосіпєд. P.S. Хоча ми з вами здогадуємося, що...
              Може, як ви прочитаєте цю книжку, то вам відкриється: сила і слабкість, вічне і дріб'язок, життя і смерть. І, може, прочитавши цей твір, ви скажете, що луччє: пизда чи вєлосіпєд. P.S. Хоча ми з вами здогадуємося, що...
            </div>
          </div>
          <div className={"note"}>
            <div className={"title"}>Мої думки тихі</div>
            <div className={"payload"}>
              Може, як ви прочитаєте цю книжку, то вам відкриється: сила і слабкість, вічне і дріб'язок, життя і смерть. І, може, прочитавши цей твір, ви скажете, що луччє: пизда чи вєлосіпєд. P.S. Хоча ми з вами здогадуємося, що...
              Може, як ви прочитаєте цю книжку, то вам відкриється: сила і слабкість, вічне і дріб'язок, життя і смерть. І, може, прочитавши цей твір, ви скажете, що луччє: пизда чи вєлосіпєд. P.S. Хоча ми з вами здогадуємося, що...
            </div>
          </div>
        </div>
        <div className={"buttons"}>
          <button className={"create"}>Нотатка</button>
          <button className={"create"}>Список</button>
        </div>
      </div>}

      {activePage === 2 && <div className={"page note-page"}>
        <div className={"header"}>
          <div className={"back"}></div>
          <div className={"info"}>Виконано 2 з 5</div>
          <input className={"title-input"} type={"text"} placeholder={"Назва..."}/>
        </div>
        <div className={"content"}>
          <p className={"plaintext"} contentEditable={true} placeholder={"Введіть текст нотатки..."}>
            Може, як ви прочитаєте цю книжку, то вам відкриється: сила і слабкість, вічне і дріб'язок, життя і смерть. І, може, прочитавши цей твір, ви скажете, що луччє: пизда чи вєлосіпєд.

            P.S. Хоча ми з вами здогадуємося, що...
          </p>
        </div>
      </div>}
      {activePage === 3 && <div className={"page note-page"}>
        <div className={"header"}>
          <div className={"back"}></div>
          <div className={"info"}>Виконано 2 з 5</div>
          <input className={"title-input"} type={"text"} placeholder={"Назва..."}/>
        </div>
        <div className={"content"}>
          <div className={"list-item"}>
            <div className={"text"} contentEditable={true}>sdfs</div>
          </div>
          <div className={"list-item"} data-selected={true}>
            <div className={"text"} contentEditable={true}>Вамвам віам віамва апапа мсівмсівмів іва в іам іва івма </div>
          </div>
          <div className={"list-item"}>
            <div className={"text"} contentEditable={true}>sdfs</div>
          </div>
          <div className={"list-item"} data-done={true}>
            <div className={"text"} contentEditable={true}>віамва апапа мсівмсівмів іва в іам іва івма</div>
          </div>
          <div className={"list-item"}>
            <div className={"text"} contentEditable={true}>sdfs</div>
          </div>
          <div className={"list-item"}>
            <div className={"text"} contentEditable={true}>sdfs</div>
          </div>
          <div className={"list-item"}>
            <div className={"text"} contentEditable={true}>Вамвам віам віамва апапа мсівмсівмів іва в іам іва івма </div>
          </div>
          <div className={"list-item"}>
            <div className={"text"} contentEditable={true}>sdfs</div>
          </div>
          <div className={"list-item"} data-done={true}>
            <div className={"text"} contentEditable={true}>віамва апапа мсівмсівмів іва в іам іва івма</div>
          </div>
          <div className={"list-item"}>
            <div className={"text"} contentEditable={true}>sdfs</div>
          </div>
          <div className={"list-item"}>
            <div className={"text"} contentEditable={true}>sdfs</div>
          </div>
          <div className={"list-item"}>
            <div className={"text"} contentEditable={true}>Вамвам віам віамва апапа мсівмсівмів іва в іам іва івма </div>
          </div>
          <div className={"list-item"}>
            <div className={"text"} contentEditable={true}>sdfs</div>
          </div>
          <div className={"list-item"} data-done={true}>
            <div className={"text"} contentEditable={true}>віамва апапа мсівмсівмів іва в іам іва івма</div>
          </div>
          <div className={"list-item"}>
            <div className={"text"} contentEditable={true}>sdfs</div>
          </div>
          <div className={"add-item"}>
            Додати ще один пункт
          </div>
        </div>
      </div>}
      {activePage === 4 && <div className={"page profile-page"}>
        <div className={"avatar"}></div>
        <div className={"email"}>alexflomboimt@gmail.com</div>
        <button className={"exit"}>Вийти</button>
      </div>}
      {activePage === 5 && <div className={"page profile-page"}>
        <div className={"alert"}>
          <div className={"window"}>
            <div className={"icon"}></div>
            <div className={"text"}>Немає зв’язку. Перевірте інтернет</div>
            <div className={"buttons"}>
              <button>Добре</button>
              <button>Відмінити</button>
            </div>
          </div>
        </div>
      </div>}
    </div>
  }

}

export default App;
