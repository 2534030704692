import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import {Routes, Route, Outlet, Link, useParams} from "react-router-dom";
  import {notesStore} from "../../stores/NotesStore";
import {PlaintextNote} from "./plaintextnote/PlaintextNote";
import {NOTE_TYPE, PLAINTEXT_MAX} from "../../utils/const";
import {ChecklistNote} from "./checklistnote/ChecklistNote";
import {nowSec} from "../../utils/common";
import {observer} from "mobx-react";
import {userStore} from "../../stores/UserStore";
import {useTranslation} from "react-i18next";

export const NotePage = observer(({notesStore}) => {

  //const [visible, setVisible] = useState(props.visible);
  //const [active, setActive] = useState(0);


  let {id} = useParams();

  const note = notesStore.getNoteById(id);


  const [isScrolled, setIsScrolled] = useState(false);
  const [plaintextLength, setPlaintextLength] = useState(0);

  useEffect(() => {

  }, [])



  function onScroll(e) {
    //console.log("!!!", e.target.scrollTop);
    setIsScrolled(e.target.scrollTop !== 0);
  }


  function titleShouldBeFocused() {
    return false;
    /*if(note.title === "") {
      if(note.type === NOTE_TYPE.PLAINTEXT) {
        return true;
      } else if(note.type === NOTE_TYPE.CHECKLIST) {
        if(note.items.length === 0) {
          return true;
        }
      }
    }
    return false;*/
  }

  if(!userStore.firebaseUser)  return null;


  return <div className={`page note-page ${isScrolled ? "scrolled" : ""}`} onScroll={(e) => onScroll(e)}>
    <Header
        noteStore={note}
        focused={titleShouldBeFocused()}
        plaintextLength={plaintextLength}
        plaintextMax={PLAINTEXT_MAX}
    />
    {note.type === NOTE_TYPE.PLAINTEXT && <PlaintextNote noteId={id} max={PLAINTEXT_MAX} onTextChanged={val => setPlaintextLength(val)}/>}
    {note.type === NOTE_TYPE.CHECKLIST && <ChecklistNote note={notesStore.getNoteById(id)}/>}
  </div>
});


export const Header = observer(({noteStore, focused, plaintextLength, plaintextMax}) => {
  let titleRef = useRef();
  const [title, setTitle] = useState(noteStore.title);

  const { t } = useTranslation();

  useEffect(() => {
    if(focused) titleRef.current.focus();
  }, [focused])

  function onTitleChange(e) {
    let text = titleRef.current.value;
    setTitle(text);
  }


  function onFinishedChangingTitle(e) {
    if(noteStore.title !== title) {
      noteStore.title = title;
      noteStore.updatedTime = nowSec();
      notesStore.saveNote(noteStore.id);

    }
  }

  //let info =
  let info = "";
  if(noteStore.type === NOTE_TYPE.CHECKLIST) {
    let countDone = noteStore.items.filter(item => item.done).length;
    let countTotal = noteStore.items.length;
    info = t("Note page. Checklist. Header info", {countDone, countTotal});
  } else {
    info = t("Note page. Plaintext. Header info", {textLength: plaintextLength, max: plaintextMax});
  }


  return <div className={"header"}>
    <Link to={"/notes"}><div className={"back"}></div></Link>
    <div className={"info"}>{info}</div>
    <input
        ref={titleRef}
        className={"title-input"}
        type={"text"}
        placeholder={t("Note page. Checklist. Title placeholder")}
        value={title}
        onChange={onTitleChange}
        onBlur={onFinishedChangingTitle}
    />
  </div>
});


