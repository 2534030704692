export function getDeviceInfo(partNumber) {
  console.log(partNumber);
  for(let i=0;i<DEVICE_PART_NUMBERS.length;i++) {
    const data = DEVICE_PART_NUMBERS[i];
    if(data["hardwarePartNumber"] === partNumber)
      return data;

    for(let j=0;j<data["partNumbers"].length;j++) {
      if(data["partNumbers"][j] == partNumber) {
        return data;
      }
    }
  }
  return null;
}
export const DEVICE_PART_NUMBERS = [
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "approachs60",
    "displayName": "Approach® S60",
    "hardwarePartNumber": "010-01702-00",
    "partNumbers": ["006-B2656-00", "006-B2907-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01702-00/v/cf-sm.jpg",
  },
  {
    "deviceFamily": "round-260x260",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "approachs62",
    "displayName": "Approach® S62",
    "hardwarePartNumber": "010-02200-00",
    "partNumbers": ["006-B3393-00", "006-B3700-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02200-00/v/cf-sm-bc8fd553-731f-4fc3-98dd-ef9eb4d7d597.jpg",
  },
  {
    "deviceFamily": "round-390x390",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "d2air",
    "displayName": "D2™ Air",
    "hardwarePartNumber": "010-02173-41",
    "partNumbers": ["006-B2187-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02173-41/g/cf-sm-11d59984-bc91-49ed-bfe9-d2ddc7c627a8.jpg",
  },
  {
    "deviceFamily": "round-416x416",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "d2airx10",
    "displayName": "D2™ Air X10",
    "hardwarePartNumber": "010-02496-09",
    "partNumbers": ["006-B2187-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02496-09/v/cf-sm-f80be13b-f121-40f1-ba82-d2571df9c634.jpg",
  },
  {
    "deviceFamily": "round-218x218",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "d2bravo",
    "displayName": "D2™ Bravo",
    "hardwarePartNumber": "010-01338-31",
    "partNumbers": ["006-B2262-00", "006-B2467-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01338-31/v/cf-sm.jpg",
  },
  {
    "deviceFamily": "round-218x218",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "d2bravo_titanium",
    "displayName": "D2™ Bravo Titanium",
    "hardwarePartNumber": "010-01338-36",
    "partNumbers": ["006-B2547-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01338-36/v/cf-sm.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "d2charlie",
    "displayName": "D2™ Charlie",
    "hardwarePartNumber": "010-01733-32",
    "partNumbers": ["006-B2819-00", "006-B2994-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01733-30/v/cf-sm.jpg",
  },
  {"deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "d2delta",
    "displayName": "D2™ Delta",
    "hardwarePartNumber": "010-01988-30",
    "partNumbers": ["006-B3197-00", ],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01988-30/g/cf-sm-87bb0674-cc55-4a52-ad94-32bf9b4e729c.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "d2deltapx",
    "displayName": "D2™ Delta PX",
    "hardwarePartNumber": "010-01989-30",
    "partNumbers": ["006-B3198-00", "006-B3324-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01989-30/g/cf-sm-874810a3-a28b-4484-955d-6c4a24c4f7bf.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "d2deltas",
    "displayName": "D2™ Delta S",
    "hardwarePartNumber": "010-01987-30",
    "partNumbers": ["006-B3196-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01987-30/g/cf-sm-431a8fa0-3449-43e0-9702-5ff6ad991c84.jpg",
  },
  {
    "deviceFamily": "round-416x416",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "d2mach1",
    "displayName": "D2™ Mach 1",
    "hardwarePartNumber": "010-02582-54",
    "partNumbers": ["006-B4079-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02582-54/v/cf-sm-4e5412c4-10ee-4008-9436-e9bc51f60a0f.jpg",
  },
  {
    "deviceFamily": "semioctagon-176x176",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "descentg1",
    "displayName": "Descent™ G1 / G1 Solar",
    "hardwarePartNumber": "010-02604-02",
    "partNumbers": ["006-B4005-00", "006-B4132-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02604-02/v/cf-sm-69f99ebc-edc0-45c4-91ee-5a92cee5477f.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "descentmk1",
    "displayName": "Descent™ Mk1",
    "hardwarePartNumber": "010-01760-00",
    "partNumbers": ["006-B2859-00", "006-B2991-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01760-00/v/cf-sm.jpg",
  },
  {
    "deviceFamily": "round-280x280",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "descentmk2",
    "displayName": "Descent™ Mk2 / Descent™ Mk2i",
    "hardwarePartNumber": "010-02132-00",
    "partNumbers": ["006-B3258-00", "006-B3702-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02132-00/v/cf-sm-e9552746-bff8-49ce-9a36-54f7f69c896a.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "descentmk2s",
    "displayName": "Descent™ Mk2 S",
    "hardwarePartNumber": "010-02403-03",
    "partNumbers": ["006-B3542-00", "006-B3930-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02403-03/v/cf-sm-9160c2ec-b9cc-49c3-abca-152044d14084.jpg",
  },
  {
    "deviceFamily": "round-280x280",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "enduro",
    "displayName": "Enduro™",
    "hardwarePartNumber": "010-02408-00",
    "partNumbers": ["006-B3638-00", "006-B3872-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02408-00/v/cf-sm-5eb1336c-07e9-424b-a8d1-a3fa74bf3413.jpg",
  },
  {
    "deviceFamily": "rectangle-205x148",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "epix",
    "displayName": "epix™",
    "hardwarePartNumber": "010-01247-00",
    "partNumbers": ["006-B1988-00", "006-B2332-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01247-00/g/cf-sm.jpg",
  },
  {
    "deviceFamily": "round-416x416",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "epix2",
    "displayName": "epix™ (Gen 2) / quatix® 7 Sapphire",
    "hardwarePartNumber": "010-02582-10",
    "partNumbers": ["006-B3943-00", "006-B3944-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02582-10/v/cf-sm-9426f26d-0453-491f-bad1-5578abef3d60.jpg",
  },
  {
    "deviceFamily": "round-218x218",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix3",
    "displayName": "fēnix® 3 / tactix® Bravo / quatix® 3",
    "hardwarePartNumber": "010-01338-00",
    "partNumbers": ["006-B2050-00", "006-B2188-00", "006-B2189-00", "006-B2293-00", "006-B2407-00", "006-B2408-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01338-00/v/cf-sm.jpg",
  },
  {
    "deviceFamily": "round-218x218",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix3_hr",
    "displayName": "fēnix® 3 HR",
    "hardwarePartNumber": "010-01338-70",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01338-70/g/cf-sm.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix5",
    "displayName": "fēnix® 5 / quatix® 5",
    "hardwarePartNumber": "010-01688-10",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01688-00/v/cf-sm.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix5plus",
    "displayName": "fēnix® 5 Plus",
    "hardwarePartNumber": "010-01988-00",
    "partNumbers": ["006-B3110-00", "006-B3089-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01988-10/v/cf-sm-c9091bfe-f1af-42b4-bd62-f3b45f46a15d.jpg",
  },
  {
    "deviceFamily": "round-218x218",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix5s",
    "displayName": "fēnix® 5S",
    "hardwarePartNumber": "010-01685-02",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01685-00/v/cf-md.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix5splus",
    "displayName": "fēnix® 5S Plus",
    "hardwarePartNumber": "010-01987-02",
    "partNumbers": ["006-B2900-00", "006-B3134-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01987-00/v/cf-sm-58f98bed-90e7-4199-b4bc-925aec9690a7.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix5x",
    "displayName": "fēnix® 5X / tactix® Charlie",
    "hardwarePartNumber": "010-01989-00",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01733-00/v/cf-md.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix5xplus",
    "displayName": "fēnix® 5X Plus",
    "hardwarePartNumber": "010-01989-00",
    "partNumbers": ["006-B3111-00", "006-B3135-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01989-04/v/cf-sm-6dd29412-ddad-4f53-b62d-84eaaa06f258.jpg",
  },
  {
    "deviceFamily": "round-260x260",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix6",
    "displayName": "fēnix® 6 / 6 Solar / 6 Dual Power",
    "hardwarePartNumber": "010-02158-00",
    "partNumbers": ["006-B3289-00", "006-B3514-00", "006-B3766-00", "006-B3770-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02158-11/v/cf-sm-d9660dd6-f71d-4fe6-8682-2f6d80e12dff.jpg",
  },
  {
    "deviceFamily": "round-260x260",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix6pro",
    "displayName": "fēnix® 6 Pro / 6 Sapphire / 6 Pro Solar / 6 Pro Dual Power / quatix® 6",
    "hardwarePartNumber": "010-02158-01",
    "partNumbers": ["006-B3290-00", "006-B3515-00", "006-B3782-00", "006-B3767-00", "006-B3771-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02158-02/v/cf-sm-c561cac8-1a27-4bb1-8374-d99dc6afe57a.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix6s",
    "displayName": "fēnix® 6S / 6S Solar / 6S Dual Power",
    "hardwarePartNumber": "010-02159-01",
    "partNumbers": ["006-B3287-00", "006-B3512-00", "006-B3764-00", "006-B3768-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02159-00/v/cf-sm-880ca1fb-2302-4a53-bfda-962e357fd7f8.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix6spro",
    "displayName": "fēnix® 6S Pro / 6S Sapphire / 6S Pro Solar / 6S Pro Dual Power",
    "hardwarePartNumber": "010-02159-13",
    "partNumbers": ["006-B3288-00", "006-B3513-00", "006-B3765-00", "006-B3769-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02159-14/v/cf-sm-6f0a6934-59f4-467c-8a6d-e74cb01d5ca6.jpg",
  },
  {
    "deviceFamily": "round-280x280",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix6xpro",
    "displayName": "fēnix® 6X Pro / 6X Sapphire / 6X Pro Solar / tactix® Delta Sapphire / Delta Solar / Delta Solar - Ballistics Edition / quatix® 6X / 6X Solar / 6X Dual Power",
    "hardwarePartNumber": "010-02157-00",
    "partNumbers": ["006-B3291-00", "006-B3516-00", "006-B3783-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02157-01/v/cf-sm-47dff6dd-beb7-42eb-9daa-3585c81c12ea.jpg",
  },
  {
    "deviceFamily": "round-260x260",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix7",
    "displayName": "fēnix® 7 / quatix® 7",
    "hardwarePartNumber": "010-02540-20",
    "partNumbers": ["006-B3906-00", "006-B3909-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02540-20/v/cf-sm-650273c3-5d6c-4615-b9b0-dc811c4755ac.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix7s",
    "displayName": "fēnix® 7S",
    "hardwarePartNumber": "010-02539-24",
    "partNumbers": ["006-B3905-00", "006-B3908-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02539-24/v/cf-sm-1a4617c4-fb5f-465d-984b-0855ebd713e0.jpg",
  },
  {
    "deviceFamily": "round-260x260",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix7system6preview",
    "displayName": "fēnix® 7 System 6 Preview",
    "hardwarePartNumber": "010-02540-20",
    "partNumbers": ["006-B9997-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02540-20/v/cf-sm-650273c3-5d6c-4615-b9b0-dc811c4755ac.jpg",
  },
  {
    "deviceFamily": "round-280x280",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenix7x",
    "displayName": "fēnix® 7X / tactix® 7 / quatix® 7X Solar / Enduro™ 2",
    "hardwarePartNumber": "010-02541-10",
    "partNumbers": ["006-B3907-00", "006-B3910-00", "006-B4135-00", "006-B4341-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02541-10/v/cf-sm-eb83ca4d-b5e7-4ba8-a36b-5633b4d901bd.jpg",
  },
  {
    "deviceFamily": "round-218x218",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fenixchronos",
    "displayName": "fēnix® Chronos",
    "hardwarePartNumber": "010-01957-00",
    "partNumbers": ["006-B2432-00", "006-B2675-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01957-00/v/cf-sm.jpg",
  },
  {
    "deviceFamily": "semiround-215x180",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr230",
    "displayName": "Forerunner® 230",
    "hardwarePartNumber": "010-03717-40",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-03717-40/g/cf-sm.jpg",
  },
  {
    "deviceFamily": "semiround-215x180",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr235",
    "displayName": "Forerunner® 235",
    "hardwarePartNumber": "010-03717-54",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-03717-54/v/cf-sm.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr245",
    "displayName": "Forerunner® 245",
    "hardwarePartNumber": "010-02120-00",
    "partNumbers": ["006-B3076-00", "006-B3145-00", "006-B3914-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02120-00/v/cf-sm-13aa6bd1-4382-4520-ba05-be6a77decdbf.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr245m",
    "displayName": "Forerunner® 245 Music",
    "hardwarePartNumber": "010-02120-20",
    "partNumbers": ["006-B3077-00", "006-B3321-00", "006-B3913-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02120-20/v/cf-sm-3f7eab23-b754-4e70-aba1-518f230a57f7.jpg",
  },
  {
    "deviceFamily": "round-260x260",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr255",
    "displayName": "Forerunner® 255",
    "hardwarePartNumber": "010-02641-00",
    "partNumbers": ["006-B3992-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02641-00/v/cf-sm-00cd5b03-2375-4894-9a15-b4ea32638146.jpg",
  },
  {
    "deviceFamily": "round-260x260",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr255m",
    "displayName": "Forerunner® 255 Music",
    "hardwarePartNumber": "010-02641-20",
    "partNumbers": ["006-B3990-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02641-20/v/cf-sm-29ca4524-07b7-403d-8b88-1f30bbf194d1.jpg",
  },
  {
    "deviceFamily": "round-218x218",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr255s",
    "displayName": "Forerunner® 255s",
    "hardwarePartNumber": "010-02641-02",
    "partNumbers": ["006-B3993-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02641-02/v/cf-sm-ad4021ee-c827-4f09-8072-c91e0f02c877.jpg",
  },
  {
    "deviceFamily": "round-218x218",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr255sm",
    "displayName": "Forerunner® 255s Music",
    "hardwarePartNumber": "010-02641-22",
    "partNumbers": ["006-B3991-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02641-22/v/cf-sm-e1909be7-5796-4672-a236-7c4570765e3e.jpg",
  },
  {
    "deviceFamily": "round-208x208",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr45",
    "displayName": "Forerunner® 45",
    "hardwarePartNumber": "010-02156-05",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02156-05/v/cf-sm-ddce0f3c-16b2-4cfc-bd14-7e541f5412bc.jpg",
  },
  {
    "deviceFamily": "round-208x208",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr55",
    "displayName": "Forerunner® 55",
    "hardwarePartNumber": "010-02562-00",
    "partNumbers": ["006-B3869-00", "006-B4033-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02562-00/v/cf-sm-f2980632-d084-4bc0-a08d-f958680e2042.jpg",
  },
  {
    "deviceFamily": "semiround-215x180",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr630",
    "displayName": "Forerunner® 630",
    "hardwarePartNumber": "010-03717-00",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-03717-00/v/lf-md.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr645",
    "displayName": "Forerunner® 645",
    "hardwarePartNumber": "010-01863-00",
    "partNumbers": ["006-B2886-00", "006-B3003-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01863-10/v/cf-sm-3d860c6d-aec3-4fcd-8544-3ec55190a637.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr645m",
    "displayName": "Forerunner® 645 Music",
    "hardwarePartNumber": "010-01863-20",
    "partNumbers": ["006-B2888-00", "006-B3004-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01863-30/v/cf-sm-cf7f4202-dce2-4c43-ab20-0d0ae12b35f3.jpg",
  },
  {
    "deviceFamily": "semiround-215x180",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr735xt",
    "displayName": "Forerunner® 735xt",
    "hardwarePartNumber": "010-01614-00",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01614-00/v/cf-sm.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr745",
    "displayName": "Forerunner® 745",
    "hardwarePartNumber": "010-02445-00",
    "partNumbers": ["006-B3589-00", "006-B3794-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02445-00/v/cf-sm-533429da-6a03-47d1-a80c-5bc0194c8797.jpg",
  },
  {
    "deviceFamily": "rectangle-205x148",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr920xt",
    "displayName": "Forerunner® 920XT",
    "hardwarePartNumber": "010-01174-00",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01174-00/g/lf-md.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr935",
    "displayName": "Forerunner® 935",
    "hardwarePartNumber": "010-01746-00",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01746-00/v/cf-sm.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr945",
    "displayName": "Forerunner® 945",
    "hardwarePartNumber": "010-02063-00",
    "partNumbers": ["006-B3113-00", "006-B3441-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02063-00/v/cf-sm-538e211f-f991-4d7a-91f7-d25d4feb9a99.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr945lte",
    "displayName": "Forerunner® 945 LTE",
    "hardwarePartNumber": "010-02383-00",
    "partNumbers": ["006-B3652-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02383-00/v/cf-sm-85c53677-a902-49b1-af0b-1787c5c8cbfd.jpg",
  },
  {
    "deviceFamily": "round-260x260",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "fr955",
    "displayName": "Forerunner® 955 / Solar",
    "hardwarePartNumber": "010-02638-00",
    "partNumbers": ["006-B4024-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02638-00/v/cf-sm-3eae0cec-a5ab-453a-9cd8-37250ce4f768.jpg",
  },
  {
    "deviceFamily": "round-208x208",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "garminswim2",
    "displayName": "Garmin Swim™ 2",
    "hardwarePartNumber": "010-02247-00",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02247-00/v/cf-sm-547ef65f-7247-4c83-864e-6aad5f8fa2d5.jpg",
  },
  {
    "deviceFamily": "semioctagon-176x176",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "instinct2",
    "displayName": "Instinct® 2 / Solar / Dual Power / dēzl Edition",
    "hardwarePartNumber": "010-02626-10",
    "partNumbers": ["006-B3888-00", "006-B4071-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02626-10/v/cf-sm-fd19486f-e976-45df-bb9d-a3c6dea45eb9.jpg",
  },
  {
    "deviceFamily": "semioctagon-163x156",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "instinct2s",
    "displayName": "Instinct® 2S / Solar / Dual Power",
    "hardwarePartNumber": "010-02563-10",
    "partNumbers": ["006-B3889-00", "006-B4091-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02563-10/v/cf-sm-c75ac564-a112-4c6b-9ba6-bc0e5fad16a4.jpg",
  },
  {
    "deviceFamily": "semioctagon-176x176",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "instinctcrossover",
    "displayName": "Instinct® Crossover",
    "hardwarePartNumber": "010-02730-11",
    "partNumbers": ["006-B4155-00"],
    "imageUrl": "https://res.garmin.com/en/products/010-02730-11/v/cf-sm.jpg",
  },
  ////////////////////////////////////////////////
  ////////////////////////////////////////////////
  ////////////////////////////////////////////////
  ////////////////////////////////////////////////
  {
    "deviceFamily": "round-218x218",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "legacyherocaptainmarvel",
    "displayName": "Captain Marvel",
    "hardwarePartNumber": "010-02172-41",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02172-41/g/cf-sm-0a318bb6-9baa-4c5d-b344-19de0c656cd7.jpg",
  },
  {
    "deviceFamily": "round-260x260",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "legacyherofirstavenger",
    "displayName": "First Avenger",
    "hardwarePartNumber": "010-02174-41",
    "partNumbers": ["006-B3501-00", "006-B3536-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02174-41/g/cf-sm-f2db5e1c-5419-42b2-9f22-c4da61e9878a.jpg",
  },
  {
    "deviceFamily": "round-260x260",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "legacysagadarthvader",
    "displayName": "Darth Vader™",
    "hardwarePartNumber": "010-02174-51",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02174-51/g/cf-sm-34b5ea24-c6fe-4c85-964d-51962e9cc501.jpg",
  },
  {
    "deviceFamily": "round-218x218",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "legacysagarey",
    "displayName": "Rey™",
    "hardwarePartNumber": "010-02172-51",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02172-51/g/cf-sm-d454beb1-ffd5-4d70-9f9e-7a2202cb536c.jpg",
  },
  {
    "deviceFamily": "round-390x390",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "marq2",
    "displayName": "MARQ® (Gen 2) Athlete / Adventurer / Captain / Golfer",
    "hardwarePartNumber": "010-02648-40",
    "partNumbers": [],
    "imageUrl": "https://res.garmin.com/en/products/010-02648-40/g/cf-sm.jpg",
  },
  {
    "deviceFamily": "round-390x390",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "marq2aviator",
    "displayName": "MARQ® (Gen 2) Aviator",
    "hardwarePartNumber": "010-02648-00",
    "partNumbers": [],
    "imageUrl": "https://res.garmin.com/en/products/010-02648-00/g/cf-sm.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "marqadventurer",
    "displayName": "MARQ® Adventurer",
    "hardwarePartNumber": "010-02006-26",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02006-26/g/cf-sm-f78826d3-cb9a-42f0-871c-ca80cc34c5a4.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "marqathlete",
    "displayName": "MARQ® Athlete",
    "hardwarePartNumber": "010-02006-15",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02006-15/g/cf-sm-0b73c33d-2df0-4d7e-b438-fd23d7c4390f.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "marqaviator",
    "displayName": "MARQ® Aviator",
    "hardwarePartNumber": "010-02006-03",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02006-03/g/cf-sm-614d7b98-9b70-4c8a-bec7-5ee303166767.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "marqcaptain",
    "displayName": "MARQ® Captain / MARQ® Captain: American Magic Edition",
    "hardwarePartNumber": "010-02006-06",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02006-06/g/cf-sm-04034f67-9380-4d27-b5aa-678600fb8b5f.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "marqcommander",
    "displayName": "MARQ® Commander",
    "hardwarePartNumber": "010-02006-09",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02006-11/g/cf-sm-4a863ce3-3cca-4146-b654-bfd551156b5a.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "marqdriver",
    "displayName": "MARQ® Driver",
    "hardwarePartNumber": "010-02006-00",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02006-00/g/cf-sm-2f580581-696f-4e11-808b-c8acd963de46.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "marqexpedition",
    "displayName": "MARQ® Expedition",
    "hardwarePartNumber": "010-02006-12",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02006-12/g/cf-sm-4d82de5c-a266-4cb0-abd1-a167526e2669.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "marqgolfer",
    "displayName": "MARQ® Golfer",
    "hardwarePartNumber": "010-02394-00",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02006-15/g/cf-sm-0b73c33d-2df0-4d7e-b438-fd23d7c4390f.jpg",
  },
  {
    "deviceFamily": "round-390x390",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "venu",
    "displayName": "Venu®",
    "hardwarePartNumber": "010-02173-11",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02173-12/v/cf-sm-9af674f3-640f-43f0-91df-117ed510ffcf.jpg",
  },
  {
    "deviceFamily": "round-416x416",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "venu2",
    "displayName": "Venu® 2",
    "hardwarePartNumber": "010-02430-00",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02430-00/v/cf-sm-20cc078d-41ed-4fba-a637-f91b705917d5.jpg",
  },
  {
    "deviceFamily": "round-416x416",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "venu2plus",
    "displayName": "Venu® 2 Plus",
    "hardwarePartNumber": "010-02496-01",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02496-01/v/cf-sm-8f74e70d-0dc3-480a-b8d4-a684aaffdf4a.jpg",
  },
  {
    "deviceFamily": "round-360x360",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "venu2s",
    "displayName": "Venu® 2S",
    "hardwarePartNumber": "010-02429-00",
    "partNumbers": ["006-B3704-00", "006-B3949-00", "006-B4175-00", "006-B4181-00"],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02429-00/v/cf-sm-d3c4ea1a-a6cf-4b83-b799-c3d142efd5be.jpg",
  },
  {
    "deviceFamily": "round-416x416",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "venu2system6preview",
    "displayName": "Venu® 2 System 6 Preview",
    "hardwarePartNumber": "010-02430-00",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02430-00/v/cf-sm-20cc078d-41ed-4fba-a637-f91b705917d5.jpg",
  },
  {
    "deviceFamily": "round-390x390",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "venud",
    "displayName": "Venu® Mercedes-Benz® Collection",
    "hardwarePartNumber": "010-02173-51",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02173-12/v/cf-sm-9af674f3-640f-43f0-91df-117ed510ffcf.jpg",
  },
  {
    "deviceFamily": "rectangle-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "venusq",
    "displayName": "Venu® Sq",
    "hardwarePartNumber": "010-02427-00",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02427-00/v/cf-sm-204e4eb4-1a28-411a-840b-aae43ee5e852.jpg",
  },
  {
    "deviceFamily": "rectangle-320x360",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "venusq2",
    "displayName": "Venu® Sq 2",
    "hardwarePartNumber": "010-02701-00",
    "partNumbers": [],
    "imageUrl": "https://res.garmin.com/en/products/010-02701-00/v/cf-sm.jpg",
  },
  {
    "deviceFamily": "rectangle-320x360",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "venusq2m",
    "displayName": "Venu® Sq 2 Music",
    "hardwarePartNumber": "010-02700-00",
    "partNumbers": [],
    "imageUrl": "https://res.garmin.com/en/products/010-02700-00/v/cf-sm.jpg",
  },
  {
    "deviceFamily": "rectangle-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "venusqm",
    "displayName": "Venu® Sq. Music Edition",
    "hardwarePartNumber": "010-02426-00",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02426-00/v/cf-sm-344ec769-92f0-4a2f-9df4-7d8f0e4f0d36.jpg",
  },
  {
    "deviceFamily": "rectangle-205x148",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "vivoactive",
    "displayName": "vívoactive®",
    "hardwarePartNumber": "010-01297-00",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01297-00/v/cf-sm.jpg",
  },
  {
    "deviceFamily": "rectangle-148x205",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "vivoactive_hr",
    "displayName": "vívoactive® HR",
    "hardwarePartNumber": "010-01605-00",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01605-03/g/cf-sm.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "vivoactive3",
    "displayName": "vívoactive® 3",
    "hardwarePartNumber": "010-01769-10",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01769-00/v/cf-sm.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "vivoactive3d",
    "displayName": "vívoactive® 3 Mercedes-Benz® Collection",
    "hardwarePartNumber": "010-01769-14",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01769-01/g/cf-sm-01b9b246-bee9-46f0-a42d-db98ddfea352.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "vivoactive3m",
    "displayName": "vívoactive® 3 Music",
    "hardwarePartNumber": "010-01985-01",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01985-03/v/cf-sm-cc4e0386-75e2-4c2f-8cc5-59cca47faed6.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "vivoactive3mlte",
    "displayName": "vívoactive® 3 Music LTE",
    "hardwarePartNumber": "010-01986-00",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-01985-03/v/cf-sm-cc4e0386-75e2-4c2f-8cc5-59cca47faed6.jpg",
  },
  {
    "deviceFamily": "round-260x260",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "vivoactive4",
    "displayName": "vívoactive® 4",
    "hardwarePartNumber": "010-02174-11",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02174-12/v/cf-sm-ea8594d6-289b-4a61-b99c-0566e02a9b43.jpg",
  },
  {
    "deviceFamily": "round-218x218",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "vivoactive4s",
    "displayName": "vívoactive® 4S",
    "hardwarePartNumber": "010-02172-11",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/010-02172-32/v/cf-sm-dd867152-525f-4622-a4f4-d7a09dcea813.jpg",
  },
  {
    "deviceFamily": "round-240x240",
    "deviceGroup": "Watches/Wearables",
    "deviceId": "vivolife",
    "displayName": "vívolife",
    "hardwarePartNumber": "010-01769-D0",
    "partNumbers": [],
    "imageUrl": "https://static.garmincdn.com/en/products/imagecomingsoon.gif",
  }
]
